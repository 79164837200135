<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <permissions-form
        :project="active_project"
        @close-dialog="dialog = false"
        @update-complete="permissionsComplete"
      ></permissions-form>
    </v-dialog>

    <items-count :items="selected"></items-count>

    <v-data-table
      :items="projects"
      :headers="dynamicHeaders"
      sort-by="createdAt"
      :sort-desc="true"
      dense
      hide-default-footer
      disable-pagination
      :single-expand="true"
      :show-expand="expandable"
      item-key="_id"
      :expanded.sync="expanded"
      :search="search"
      class="elevation-4"
      @current-items="updateSummary"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          outlined
          clearable
          prepend-inner-icon="mdi-magnify"
          class="mx-4 mt-4"
        ></v-text-field>
      </template>

      <template v-slot:item.name="{ item }">
        <div v-if="item.name">
          <router-link
            :to="{
              name: 'ProjectDetails',
              params: { name: item.name },
            }"
          >
            {{ item.name }}
          </router-link>
        </div>
      </template>

      <template v-slot:item.dataproducts="{ item }">
        {{ item.dataproducts.length }}
      </template>

      <template v-slot:item.groups="{ item }">
        <group-members v-for="g in item.groups" :key="g._id" :group="g">
        </group-members>

        <!-- <v-chip small color="primary" v-for="g in item.groups" :key="g._id">
          {{ g.name }}
        </v-chip> -->
      </template>

      <template v-slot:item.users="{ item }">
        <v-chip
          small
          dense
          color="secondary"
          v-for="u in item.users"
          :key="u._id"
        >
          {{ u.fullname }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small dense color="info" dark @click="setActive(item)">
          mdi-account-edit
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PermissionsForm from '@/components/PermissionsForm'
import GroupMembers from '@/components/GroupMembers'
import ItemsCount from '@/components/ItemsCount'

export default {
  // a tiny change to test branch
  name: 'Projects',

  components: {
    PermissionsForm,
    GroupMembers,
    ItemsCount,
  },

  props: {},

  computed: {
    dynamicHeaders() {
      if (this.$store.getters.hasRole('admin')) {
        return [
          { text: 'Project', value: 'name' },
          { text: 'Data Products', value: 'dataproducts' },
          { text: 'Edit', value: 'actions' },
          { text: 'Users', value: 'users' },
          { text: 'Groups', value: 'groups' },
        ]
      } else {
        return [
          { text: 'Project', value: 'name' },
          { text: 'Data Products', value: 'dataproducts' },
        ]
      }
    },
  },
  data() {
    return {
      projects: [],

      selected: [],

      expanded: [],
      groups: [],
      users: [],
      chosen_groups: [],
      chosen_users: [],
      active_project: {
        name: '',
      },
      active_group: {
        name: '',
      },
      dialog: false,

      expandable: false,
      search: '',
    }
  },
  methods: {
    setActive(ds) {
      this.chosen_groups = ds.groups
      this.chosen_users = ds.users

      this.$nextTick(() => {
        this.active_project = ds
        this.dialog = true
      })

      console.log(this.chosen_groups)
      console.log(this.chosen_users)
    },

    getProjects() {
      if (this.$store.getters.hasRole('admin')) {
        this.$http.get(`${this.$config.api}/projects/all`).then((response) => {
          this.projects = response.data
          console.log(response.data)
        })
      } else {
        this.$http.get(`${this.$config.api}/projects/mine`).then((response) => {
          this.projects = response.data
          console.log(response.data)
        })
      }
    },
    permissionsComplete() {
      // when the dialog is complete, we need to do a bit of cleanup
      this.getProjects()
      this.dialog = false
      this.chosen_groups = []
      this.chosen_users = []
    },
    updateSummary(items) {
      this.selected = items
    },
  },
  mounted() {
    this.getProjects()
  },
  head() {
    return {
      title: this.$route.name,
    }
  },
}
</script>
