<template>
  <div>

    <v-container fluid>
      <v-progress-circular
          v-if="loading"
          :size="70"
          :width="7"
          color="blue"
          indeterminate
          class="d-flex justify-center"
      ></v-progress-circular>

      <div v-if="!loading && sessions.length">
        <v-text-field
            v-model="search"
            label="Search"
            outlined
            clearable
            prepend-inner-icon="mdi-magnify"
            class="mx-4 mt-4"
        ></v-text-field>

        <v-row dense>
          <v-col
              v-for="s in filteredSessions"
              :key="s._id"
              :cols="4"
          >
            <SessionCard :session="s" @delete="deleted_sessions.push(s._id)" @updated="update_session"></SessionCard>
          </v-col>
        </v-row>
      </div>



      <v-alert
          type="info"
          v-if="!loading && !sessions.length"
      >
        You have not created any genome web browser sessions.  Please visit the Projects or Tracks pages to create sessions from available tracks.
      </v-alert>


    </v-container>
  </div>
</template>

<script>

  import SessionCard from '@/components/SessionCard';

  export default {
    name: 'Sessions',
    components: {SessionCard},
    data() {
      return {
        search: '',
        sessions: [],
        deleted_sessions: [],
        gbrowser: false,
        showBrowser: false,
        gbrowserUrl: '',
        loading: false
      }
    },
    computed: {
      activeSessions() {
        return this.sessions.filter(s => {
          return !this.deleted_sessions.includes(s._id);
        })
      },
      filteredSessions() {
        if(this.search.length < 2) return this.activeSessions;
        let ss = this.search.toLowerCase()

        return this.activeSessions.filter(s => {
          return s.title.toLowerCase().includes(ss) ||
            s.genome.toLowerCase().includes(ss) ||
            s.genome_type.toLowerCase().includes(ss);
        })
      }
    },
    methods: {
      getSessions() {
        this.loading = true;
        this.$http
          .get(`${this.$config.api}/sessions/mine`)
          .then((response) => {
            this.sessions = response.data
            this.loading = false
            console.log(response.data)
          })
      },
      update_session(s) {
        console.log('session updated!');
        var foundIndex = this.sessions.findIndex(x => x._id == s._id);
        console.log(foundIndex);
        this.$set(this.sessions, foundIndex, s);
      }
    },
    mounted() {
      this.getSessions();
    }
  }
</script>
