<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <v-card class="pa-4 elevation-6">
          <v-row>
            <v-col cols="3" class="text-center">
              <v-gravatar
                :email="$store.state.auth.uid + '@iu.edu'"
                :size="160"
                class="elevation-6"
              />
              <br />
              <p class="caption">
                Your avatar is handled by
                <a target="_blank" href="http://gravatar.com">gravatar.com</a>
                using your account email address.
              </p>
            </v-col>
            <v-col cols="1">
              <v-divider class="ml-1" vertical></v-divider>
            </v-col>
            <v-col cols="8">
              <v-text-field
                class="form-control"
                id="inputUsername"
                placeholder="Username"
                autocomplete="off"
                v-model="$store.state.auth.uid"
                label="Username"
                disabled
              />

              <v-label
                class="v-label--active"
                active
                for="userRoles"
                style="transform(0.75);"
              >
                Roles
              </v-label>
              <div id="userRoles">
                <v-chip class="mb-2" color="info" v-for="r in roles" :key="r">{{
                  r
                }}</v-chip
                ><br />
              </div>

              <v-label
                for="groups"
                class="col-lg-2 control-label"
                style="transform(0.75);"
                >Group Membership</v-label
              >
              <div id="groups">
                <v-chip
                  class="mb-2"
                  color="primary"
                  v-for="g in user_groups"
                  :key="g.name"
                  >{{ g.name }}</v-chip
                ><br />
              </div>
            </v-col>
          </v-row>

          <v-divider />
          <span class="body-2 font-weight-light"
            >Your username, roles, and group membership can only be updated by
            site administrators.
            <a href="mailto:sca-group@iu.edu">Contact us</a> if one of these
            items needs to be updated.
          </span>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data() {
    return {
      username: this.$store.state.auth.uid,
      roles: this.$store.state.auth.roles,
      user_groups: [],
      userDetails: {},
      notifications: true,
    }
  },
  methods: {
    getUserGroups: function () {
      this.$http
        .get(`${this.$config.api}/groups/usergroups/${this.username}`)
        .then((response) => {
          this.user_groups = response.data
          console.log(response.data)
        })
    },
    getUserData: function () {
      this.$http.get(`${this.$config.api}/users/self`).then((response) => {
        this.userDetails = response.data
        console.log('Profile -> getUserData ->', response.data)
        this.notifications = this.userDetails.notifications
      })
    },
    patchUserData: function () {
      console.log('patchUserData called')
      this.userDetails.notifications = this.notifications
      this.$http
        .patch(`${this.$config.api}/users/self`, this.userDetails)
        .then((response) => {
          this.details = response.data
          console.log('Profile -> patchUserData ->', response.data)
        })
    },
  },
  mounted() {
    this.getUserGroups()
    this.getUserData()
  },
}
</script>
