<template>

    <v-card class="elevate-3">
      <v-card-title>
        <v-row>
          <v-col cols="6">
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                hide-details
                v-if="!loading && tracks.length"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
                :items="genomeTypes"
                v-model="genomeTypeFilter"
                label="Type"
                outlined
            ></v-select>
          </v-col>
          <v-col>
            <v-select
                :items="genomeValues"
                v-model="genomeValueFilter"
                label="Genome"
                outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <SessionDialog :tracks="selected" :bigbutton="true" v-if="!loading && tracks.length"></SessionDialog>
      </v-card-text>

      <v-alert
          type="info"
          v-if="!loading && !tracks.length"
      >
        You do not have any tracks available for viewing at this time.
      </v-alert>

      <v-data-table
        :items="filteredTracks"
        :headers="headers"
        :search="search"
        show-select
        v-model="selected"
        item-key="path"
        v-if="!loading && tracks.length"
      >
        <template v-slot:item.size="props">
          {{props.item.size | prettyBytes }}
        </template>

        <template v-slot:item.createdAt="props">
          {{props.item.createdAt | moment('YYYY-MM-DD') }}
        </template>
      </v-data-table>
    </v-card>
</template>

<script>

  import SessionDialog from '@/components/SessionDialog';

  export default {
    name: 'Tracks',
    components: {SessionDialog},
    data() {
      return {
        tracks: [],
        selected: [],
        search: '',
        genomeTypeFilter: 'All',
        genomeValueFilter: 'All',
        loading: false,
        headers: [
          { value: 'filename', text: 'Filename' },
          { value: 'dataproduct_name', text: 'Source' },
          { value: 'size', text: 'Size' },
          { value: 'createdAt', text: 'Uploaded On' },
          { value: 'genomeType', text: 'Type'},
          { value: 'genomeValue', text: 'Genome'}
        ]
      }
    },
    computed: {
      genomeTypes() {
        let types = Object.keys(this.$genomes);
        types.unshift(['All']);
        return types;
      },
      genomeValues() {
        if(this.genomeTypeFilter == 'All') {
          return ['All'];
        } else {
          let genomes = this.$genomes[this.genomeTypeFilter].genomes;
          genomes.unshift(['All']);
          return genomes;
        }
      },
      filteredTracks() {
        return this.tracks.filter(t => {
          if(this.genomeTypeFilter == 'All') return true;
          if(t.genomeType == this.genomeTypeFilter) {
            if(this.genomeValueFilter == 'All') return true;
            if(t.genomeValue == this.genomeValueFilter) return true;
          }
          return false;
        })
      }
    },
    methods: {
      getTracks() {
        this.loading = true;
        this.$http
          .get(`${this.$config.api}/dataproducts/tracks`)
          .then((response) => {
            this.tracks = response.data
            this.loading = false;
            console.log(response.data)
          })
      }
    },
    mounted() {
      this.getTracks();
    }
  }
</script>
