<template>
  <div>
    <v-dialog v-model="gbrowser" max-width="1200px">

      <v-card height="90vh">
        <v-card-title class="headline grey lighten-2" primary-title>
          Genome Browser - {{session.title}}
        </v-card-title>
        <GenomeBrowser :session="session" :tab="false"></GenomeBrowser>
<!--        <v-card-text>-->
<!--          <span v-if="showBrowser">-->
<!--            <iframe :src="gbrowserUrl" height="800px" width="100%"></iframe>-->
<!--          </span>-->
<!--        </v-card-text>-->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="gbrowser = false; showBrowser = false">
            Close
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

    <v-dialog v-model="editSession" max-width="800px">

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit Session - {{session.title}}
        </v-card-title>
        <v-card-text>
          <v-text-field
              class="form-control"
              id="inputTitle"
              placeholder="Session Title"
              label="Title"
              autocomplete="off"
              v-model="localSession.title"
          />

          <v-select
              class="form-control"
              id="inputGenome"
              label="Genome"
              :items="genomeSelect"
              v-model="localSession.genome"
          />

          <v-text-field
              class="form-control"
              id="inputGenomeType"
              placeholder="Genome Type"
              label="Genome Type"
              disabled
              v-model="localSession.genome_type"
          />

          <v-list subheader dense>
            <v-subheader>Tracks</v-subheader>

            <v-list-item
                v-for="track in localSession.tracks"
                :key="track.path"
            >

              <v-list-item-content>
                <v-list-item-title v-text="track.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-btn icon color="red lighten-2" @click="removeTrack(track)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" @click="editSession = false;">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveSession">
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>


    <v-card>
      <span ref="container">
      <v-img
          :src="$genomes[session.genome_type].image"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.99)"
          height="80px"
      >
        <v-card-title v-text="session.title"></v-card-title>
      </v-img>

      <v-card-text>
        <h3>
          Genome:
          <small>{{session.genome}} ({{session.genome_type}})</small>
        </h3>
        <h3>
          Tracks:
          <small>{{session.tracks.length}}</small>
        </h3>
        <h3>
          Created: <small>{{session.createdAt | moment('YYYY-MM-DD')}}</small>
        </h3>



      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-tooltip left v-if="!onDisk">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="red lighten-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-file-cancel</v-icon>
            </v-btn>
          </template>
          <span>Some track files are not currently available.</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="copyBrowser"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-clipboard-check</v-icon>
            </v-btn>
          </template>
          <span>Copy browser link</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="openBrowser(false)"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-chart-timeline</v-icon>
            </v-btn>
          </template>
          <span>Open browser here in modal window</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                @click="openBrowser(true)"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <span>Open browser in new tab</span>
        </v-tooltip>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-share-variant</v-icon>
            </v-btn>
          </template>
          <span>Share session (coming soon)</span>
        </v-tooltip>


        <Confirm
            title="Delete Session?"
            message="Are you sure you want to delete this session?"
            color="red lighten-2"
            v-on:confirm="deleteSession"
        >
          <template v-slot:label>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="red lighten-2"
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete session</span>
            </v-tooltip>
          </template>
        </Confirm>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="blue lighten-2"
                @click="editSession = true"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit session </span>
        </v-tooltip>
      </v-card-actions>
      </span>
    </v-card>
  </div>
</template>

<script>

  import Confirm from '@/components/Confirm';
  import GenomeBrowser from '@/components/GenomeBrowser'

  export default {
    name: 'SessionCard',
    components: {Confirm, GenomeBrowser},
    props: {
      session: Object
    },
    data() {
      return {
        gbrowser: false,
        editSession: false,
        showBrowser: false,
        gbrowserUrl: '',
        hubUrl: '',
        onDisk: true,
        localSession: JSON.parse(JSON.stringify(this.session))
      }
    },
    computed: {
      genomeSelect() {
        let genomes = []
        Object.keys(this.$genomes).forEach(g => {
          genomes.push({
            header: g
          })
          this.$genomes[g].genomes.forEach(_g => {
            genomes.push({
              text: _g,
              value: _g
            })
          })
        })
        return genomes;
      }
    },
    methods: {
      makeDatahub() {
        this.hubUrl = `${this.$config.api}/sessions/datahub/${this.session._id}`;
        this.gbrowserUrl = `https://epigenomegateway.wustl.edu/browser/?genome=${this.session.genome}&hub=${this.hubUrl}`;
      },
      openBrowser(tab) {
        if(!tab) {
          this.gbrowser = true;
          this.$nextTick(() => { this.showBrowser = true; })
        } else {
          window.open(this.gbrowserUrl, '_blank');
        }
      },
      copyBrowser() {
        let container = this.$refs.container
        this.$copyText(
          this.gbrowserUrl,
          container
        ).then(
          () => {
            this.$store.dispatch(
              'snack',
              `Browser URL copied to clipboard `
            )
          },
          function (e) {
            console.log(e)
          }
        );
      },
      deleteSession() {
        this.$http
          .delete(`${this.$config.api}/sessions/${this.session._id}`)
          .then((response) => {
            console.log(response.data)
            this.$emit("delete");
          })
      },
      updateGenomeType() {
        let gt = Object.keys(this.$genomes).filter(g => {
          return this.$genomes[g].genomes.includes(this.localSession['genome'])
        })
        this.localSession.genome_type = gt[0]
      },
      removeTrack(track) {
        this.localSession.tracks = this.localSession.tracks.filter(t => {
          return t !== track;
        })
      },
      checkTracks() {
        let self = this;
        this.$http.get(`${this.$config.api}/sessions/checkFiles/${this.localSession._id}`)
        .then((response) => {
          self.onDisk = response.data.status;
        })
      },
      saveSession() {
        this.$http
          .patch(`${this.$config.api}/sessions/${this.localSession._id}`, this.localSession)
          .then((response) => {
            console.log(response.data)
            this.$emit("updated", this.localSession);
            this.editSession = false;
            this.$store.dispatch(
              'snack',
              `Session updated`
            )
          }, function(e) {
            console.log(e);
            this.$store.dispatch(
              'snack', {msg: e, isError: true}
            )
          })
      }
    },
    mounted() {
      this.makeDatahub();
      this.checkTracks();
    },
    watch: {
      localSession: {
        handler: function(val) {
          console.log(val);
          this.updateGenomeType()
        },
        deep: true
      }
    }
  }
</script>
