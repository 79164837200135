var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('permissions-form',{attrs:{"project":_vm.active_project},on:{"close-dialog":function($event){_vm.dialog = false},"update-complete":_vm.permissionsComplete}})],1),_c('items-count',{attrs:{"items":_vm.selected}}),_c('v-data-table',{staticClass:"elevation-4",attrs:{"items":_vm.projects,"headers":_vm.dynamicHeaders,"sort-by":"createdAt","sort-desc":true,"dense":"","hide-default-footer":"","disable-pagination":"","single-expand":true,"show-expand":_vm.expandable,"item-key":"_id","expanded":_vm.expanded,"search":_vm.search},on:{"update:expanded":function($event){_vm.expanded=$event},"current-items":_vm.updateSummary},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 mt-4",attrs:{"label":"Search","outlined":"","clearable":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',[_c('router-link',{attrs:{"to":{
            name: 'ProjectDetails',
            params: { name: item.name },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()]}},{key:"item.dataproducts",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.dataproducts.length)+" ")]}},{key:"item.groups",fn:function(ref){
          var item = ref.item;
return _vm._l((item.groups),function(g){return _c('group-members',{key:g._id,attrs:{"group":g}})})}},{key:"item.users",fn:function(ref){
          var item = ref.item;
return _vm._l((item.users),function(u){return _c('v-chip',{key:u._id,attrs:{"small":"","dense":"","color":"secondary"}},[_vm._v(" "+_vm._s(u.fullname)+" ")])})}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","dense":"","color":"info","dark":""},on:{"click":function($event){return _vm.setActive(item)}}},[_vm._v(" mdi-account-edit ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }